/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import './var.scss';

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "npm:bootstrap/scss/bootstrap-reboot.scss";
@import "npm:bootstrap/scss/mixins/_breakpoints.scss";
@import "npm:bootstrap/scss/utilities/_display.scss";
@import "npm:bootstrap/scss/utilities/_flex.scss";
@import "npm:bootstrap/scss/utilities/_spacing.scss";

/*!
 * Application
 */

html, body {
  margin: 0;
  height: 100vh;
  background: $global-background-color;
}

.text-capitalize {
  text-transform: capitalize!important;
}

.text-nowrap {
  white-space: nowrap!important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center!important;
}

.text-right {
  text-align: right!important;
}

.scroll-y {
  overflow-y: scroll;
  overflow: auto;
}

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #eee;
  border: 1px solid #999;
  color: #444;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &.disabled {
    opacity: 0.65;
    pointer-events: none;
  }
}

.btn-primary {
  background-color: #444;
  color: white;
  border-color: #222;
  &:hover {
    color: white;
  }
  &.disabled {
    background-color: #212529;
    border-color: #212529;
  }
}

.form-control {
  display: block;
  width: 100%;
  border: 1px solid #999;
  padding: 0.25em 0.5em;
  border-radius: .25rem;
}

.form-group {
  margin-bottom: 1em;
}

.input-group {
  display: flex;
}

.input-group .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.input-group .input-group-append .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
